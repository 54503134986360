<template>
  <div id="divLearnWrapper">
    <div class="header-wrapper">
      <VueContentHolder v-for="(module, index) in academyModules" :key="index" :padding="[15, 20]">
        <div v-if="getModuleType() === module.menuName">
          <VueHeadline weightLevel="3" level="2" color="grey-50">{{ module.name }}</VueHeadline>
          <VueSpacer level="20"></VueSpacer>

          <div class="level-card-wrapper">
            <BrandInfoCard
              @click="goToAcademy(type)"
              v-for="(type, i) in module.types"
              :key="type.contentTypeId"
              :bg-color="infoCardBgColor(type, i)"
              :sizeLevel="'4'"
              :title="type.contentName"
              class="level-card-wrapper-item"
              :bottomText="false"
              :academyParentTypeId="module.parentTypeId"
              :infoCardStyles="infoCardBgImage(type)"
            />
          </div>
        </div>
      </VueContentHolder>
    </div>

    <div class="calculator-wrapper" v-if="shouldShowCalculator">
      <VueHeadline weightLevel="3" level="2" color="grey-50">Hesaplama Araçları</VueHeadline>
      <VueSpacer level="20"></VueSpacer>

      <div v-for="item in calcLinkItems" :key="item.path">
        <VueButton
          :size="sizes.xLarge"
          class="button-link"
          color="white"
          :target="item.path"
          as="router-link"
          :outlined="true"
          :rightIcon="icon.right"
          :iconColor="'#23303d'"
        >
          <VueText class="calcButtonText" weightLevel="2">{{ item.name }}</VueText>
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesLearn from '@/router/routes/secure/RoutesLearn';
import BrandInfoCard from '@/components/brand/BrandInfoCard/BrandInfoCard.vue';
import { Learn } from '@/services/Api/index';
import AccountMixin from '@/utils/accountUtils.js';
import StorageProps from '@/mixins/storageProps.js';

export default {
  name: 'Home',
  mixins: [AccountMixin, StorageProps],
  components: { VueText, VueButton, VueContentHolder, VueHeadline, VueSpacer, BrandInfoCard },
  constants: {
    MODULE_TYPES: {
      DIGITAL_ACADEMY: 'Dijital Akademi',
      ENTREPRENEURSHIP_ACADEMY: 'Girişimcilik Akademi',
    },
  },
  data() {
    const { MODULE_TYPES } = this.$options.constants;

    return {
      academyModules: [
        {
          name: 'Girişimcilik',
          parentTypeId: 452,
          menuName: MODULE_TYPES.ENTREPRENEURSHIP_ACADEMY,
          types: [],
        },
        {
          name: 'Dijitalleşiyorum',
          parentTypeId: 26,
          menuName: MODULE_TYPES.DIGITAL_ACADEMY,
          types: [],
        },
      ],
      contentTypes: null,
      calcLinkItems: [
        {
          name: 'Yok satarak ne kadar kaybediyorsunuz?',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.TotalLoss.path}`,
        },
        {
          name: 'Ciro dağılımınızı öğrenin',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.Turnover.path}`,
        },
        {
          name: 'Kârınızı hesaplayın',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.Endorsement.path}`,
        },
        {
          name: 'Hangi üründen ne kadar kâr ediyorsunuz?',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.EndorsementByCategory.path}`,
        },
      ],
    };
  },
  computed: {
    icon() {
      return {
        right: ICON_VARIABLES['right'],
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    shouldShowCalculator() {
      const { MODULE_TYPES } = this.$options.constants;
      return this.isPartner && this.getModuleType() === MODULE_TYPES.DIGITAL_ACADEMY;
    },
  },
  async created() {
    await this.getLearnContentTypes();
  },

  methods: {
    getModuleType() {
      return this.$route.query.type;
    },
    async getLearnContentTypes() {
      const response = await Learn.getLearnContentTypes();
      if (!response?.data?.Data) return;

      const { contentTypes } = response.data.Data;
      this.academyModules.forEach(module => {
        const hasParentType = contentTypes.some(type => type.parentTypeId === module.parentTypeId);

        if (hasParentType) {
          module.types.push(
            ...contentTypes.filter(type => type.parentTypeId === module.parentTypeId),
          );
        }
      });
    },
    goToAcademy(page) {
      const url =
        page.parentTypeId == 26
          ? {
              name: RoutesLearn.DigitalLiteracy.name,
              params: page,
            }
          : {
              path: `${RoutesRoot.Secure.path}/${RoutesSecure.Entrepreneurship.path}`,
              query: { parentId: page.parentTypeId, id: page.contentTypeId },
            };
      this.$router.push(url);
    },
    infoCardBgColor(type, index) {
      return index === 0 ? 'yellow' : index === 1 ? 'blue' : 'green';
    },
    infoCardBgImage(type) {
      return {
        backgroundImage:
          type.parentTypeId == 452
            ? `url(${require(`@/assets/bg/entrepreneurship-${type.contentTypeId}.png`)})`
            : '',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.level-card-wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  &-item {
    width: 110px;
    height: 110px;
    padding: palette-space-level('10');
    color: palette-color-level('white', '100');
    margin-right: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.calculator-wrapper {
  padding: palette-space-level('20');
}

.header-wrapper {
  border-bottom: 1px solid palette-color-level('grey', '20');
  width: 100%;
  padding: palette-space-level(0);
}

.button-link {
  border-radius: 4px;
  border: solid 1px palette-color-level('grey', '10');
  margin-bottom: palette-space-level(20);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  white-space: normal;
  @media screen and (max-width: 360px) {
    padding: 15px;
  }
}
.calcButtonText {
  font-size: 16px;
  @media screen and (max-width: 360px) {
    font-size: 14px;
  }
}
</style>
